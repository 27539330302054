p {
        text-justify: inter-word;
}

.modal {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(35, 62, 89, 0.5);
        z-index: 1;
        justify-content: center;
        cursor: pointer;
}

.modal-on {
        display: flex;
}

.modal-off {
        display: none;
}

.m-content {
        background-color: #090B0D;
        height: 80%;
        overflow-y: scroll;
}

#sm-img {
        width: 100%;
        object-fit: contain;
}

#sm-p {
        margin-top: 6px;
        padding: 8px;
}

.showcase {
        display: flex;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
}

.sc-item {
        width: 100%;
        height: 20vh;
        background-color: #233E59;
        display: flex;
        justify-content: center;
        transition: border-radius 0.45s;
        align-items: center;
        position: relative;
        border-radius: 12px;
}

.sc-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
        transition: border-radius 0.45s;
}

.sc-caption {
        /* opacity: 0%; */
        opacity: 100%;
        border-radius: 12px;
        /* transition: opacity 0.6s; */
        position: absolute;
        bottom: 0;
        height: 70px;
        background-color: rgba(35, 62, 89, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
}

.sc-detail {
        display: none;
}

.sc-caption > span {
        font-size: 14px;
        color: #728CA6;
}

.contact {
        display: flex;
}

.c-platform {
        min-width: 80px;
}

.c-seperator {
        min-width: 20px;
}

.c-contact {
        color: #728CA6;
}

@media only screen and (min-width: 600px) {
        .contact {
                margin-bottom: 8px;
        }

        .sc-item {
                height: 260px;
        }
}

@media only screen and (min-width: 768px) {        
        .contact {
                margin-bottom: 12px;
        }

        .m-content {
                cursor: auto;
                padding: 12px;
                border-radius: 12px;
                width: 80%;
                height: auto;
                margin: 30px 0;
        }

        #sm-img {
                border-radius: 8px;
                height: 55%;
        }

        .sc-item {
                width: 31%;
                flex-grow: 1;
                padding: 4px;
        }

        .sc-item:hover {
                cursor: pointer;
                border-radius: 0px;
        }

        .sc-image {
                transition: border-radius 0.45s;
        }


        .sc-item:hover .sc-image {
                border-radius: 0px;
        }

        .c-platform {
                min-width: 100px;
        }

        .c-seperator {
                min-width: 30px;
        }
}
