h1, .f4 {
        font-size: 18px;
}

h2, .f3 {
        font-size: 16px;
}

h3, .f2 {
        font-size: 13px;
}

.fa {
        font-size: 10px;
}

.fb {
        font-size: 8px;
}

pre.pre-heading {
        font-size: 6px;
}

p, a, pre.code-block, li, .f1 {
        font-size: 12px;
}

#opening {
        font-size: 10px;
}

.code-block {
        overflow: scroll;
}

@media only screen and (min-width: 600px) {
        p, a, pre.code-block, li, .f1 {
                font-size: 14px;
        }

        #opening {
                font-size: 14px;
        }

        pre.pre-heading {
                font-size: 12px;
        }

        h1, .f4 {
                font-size: 22px;
        }

        h2, .f3 {
                font-size: 18px;
        }

        h3, .f2 {
                font-size: 16px;
        }

        .fa {
                font-size: 12px;
        }

        .fb {
                font-size: 10px;
        }
}

@media only screen and (min-width: 768px) {
        p, a, pre.code-block, li, .f1 {
                font-size: 16px;
        }

        #opening {
                font-size: 16px;
        }

        h1, .f4 {
                font-size: 24px; 
        }

        h2, .f3 {
                font-size: 20px;
        }

        .fa {
                font-size: 14px;
        }

        .fb {
                font-size: 12px;
        }
}


