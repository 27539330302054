:root {
        --main-color: #6D8086;
        --page-background-color: #090B0D;
        --prompt-ps-color: #4D5A5E;
        --prompt-input-color: #436B24;
        --tab-label-color: #A074C4;
        --nav-link-color: #8DC149;
}

* {
        font-family: monospace, monospace;
        color: #6D8086;
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
        overflow-wrap: break-word;
}

a {
        text-decoration: none;
        color: #728CA6;
        cursor: pointer;
}

.c1 {
        color: #00c3ff;
}

body {
        background-color: var(--page-background-color);
}

p, li {
        line-height: 24px;
}

ol {
        margin-left: 20px;
}

h1, h2, h3, h4, h5 {
        margin-bottom: 8px;
}

.primary-color {
        color: #6D8086;
        border-color: #6D8086;
}

#terminal {
    display: flex;
    justify-content: center;
    align-items: center;
}

#root {
        margin: 0 auto;
        padding: 0 12px;
        max-width: 700px;
        position: relative;
}

#nav {
        display: flex;
}

#nav a {
        color: #246B68;
        cursor: pointer;
}

.copyright {
        color: #313435;
}


h1, h2, h3, h4, .article-end-divider {
        color: #00c3ff;
        font-weight: 100;
}

/*      === NAV RULES ===       */
.nav-link-seperator {
        min-width: 20px;
}

.nav-link-seperator::before {
        content: "·";
}

.nav-link {
        color: var(--nav-link-color);
}


/*      === PROMPT RULES  ===   */
.prompt-ps {
  color: var(--prompt-ps-color);
}

.prompt-input {
        color: var(--prompt-input-color);
}


/*      === TAB RULES ===       */
.tab {
        min-width: 150px;
        display: flex;
        margin-right: 25px;
}

.tab .tab-label {
        color: var(--tab-label-color);
}

.tab .tab-seperator {
        color: var(--tab-label-color);
}


.tab .tab-value {
        margin-left: 2em;
}

.aside-divider {
        display: block;
        border-style: none;
}

.hr-dash {
        border: 0px;
        border-top: 2px solid #00c3ff;
        flex-grow: 1;
}

.divider {
        display: flex; 
        align-items: center;
}

.divider > span {
        color: #00c3ff;
}

#eof {
        font-weight: bold;
}

@media only screen and (min-width: 768px)
{
        p, li {
                line-height: 32px;
        }

        ol {
                margin-left: 40px;
        }

        #root {
                padding-top: 40px;
                max-width: 960px;
        }

        #nav {
                position: absolute;
                right: 12px;
        }

        #nav a:hover {
                color: #242C6B;
        }

        .tab {
                flex-direction: column;
        }

        .tab .tab-seperator {
                display:  none;
        }

        .tab .tab-label {
                min-width: 140px;
        }
}
